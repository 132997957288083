/* You can add global styles to this file, and also import other style files */
@import "variables";


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif }
* { box-sizing: border-box; }


.drawer-info-panel {
  .mtx-drawer-content-wrapper {
    padding: 0;
    overflow: hidden;
  }
}

.warning {
  color: $orange;
}
.error {
  color: $red;
}

.leaflet-control-container {
  display: none;
}

.map-marker {
  height: fit-content;
  width: fit-content;
  position: relative;
  display: block;
  margin-top: -41px;
  margin-left: -20px;
  .last-point {
    position: absolute;
    top: 8px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 112%; /* 17.92px */
    letter-spacing: 0.48px;
  }
  //background-image: url("assets/imgs/icons/utax_pin.svg");
  //background-size:100%;
  //width: 50px;
  //height: 50px;
  //transform: translate(-25px, -35px);
}

.iconAnchorEnd {
  top: 50px;
}

.snackbar {
  .mat-mdc-snackbar-surface {
    background: #335689 !important;
    border-radius: 12px !important;
  }
}
