// This file was generated by running 'ng generate @angular/material:m3-theme'.
// Proceed with caution if making changes to this file.

@use 'sass:map';
@use '../node_modules/@angular/material/index' as mat;
@import '../node_modules/@ng-matero/extensions/prebuilt-themes/azure-blue.css';

// Note: Color palettes are generated from primary: #ffffff
$_palettes: (
  primary: (
    0: #000000,
    10: #001f24,
    20: #00363d,
    25: #00424a,
    30: #004f58,
    35: #005b66,
    40: #006874,
    50: #008391,
    60: #00a0b0,
    70: #22bccf,
    80: #4fd8eb,
    90: #97f0ff,
    95: #d0f8ff,
    98: #edfcff,
    99: #f6feff,
    100: #ffffff,
  ),
  secondary: (
    0: #000000,
    10: #051f23,
    20: #1c3438,
    25: #273f43,
    30: #334b4f,
    35: #3e565b,
    40: #4a6267,
    50: #637b80,
    60: #7c959a,
    70: #96b0b4,
    80: #b1cbd0,
    90: #cde7ec,
    95: #dbf6fa,
    98: #edfcff,
    99: #f6feff,
    100: #ffffff,
  ),
  tertiary: (
    0: #000000,
    10: #0e1b37,
    20: #24304d,
    25: #2f3b58,
    30: #3b4664,
    35: #465271,
    40: #525e7d,
    50: #6b7697,
    60: #8490b2,
    70: #9faace,
    80: #bac6ea,
    90: #dae2ff,
    95: #eef0ff,
    98: #faf8ff,
    99: #fefbff,
    100: #ffffff,
  ),
  neutral: (
    0: #000000,
    10: #191c1d,
    20: #2e3132,
    25: #393c3d,
    30: #444748,
    35: #505354,
    40: #5c5f5f,
    50: #747878,
    60: #8e9192,
    70: #a9acac,
    80: #c4c7c7,
    90: #e1e3e3,
    95: #eff1f1,
    98: #f8fafa,
    99: #fafdfd,
    100: #ffffff,
    4: #0b0f0f,
    6: #101415,
    12: #1d2021,
    17: #272b2b,
    22: #323536,
    24: #363a3a,
    87: #d8dadb,
    92: #e6e8e9,
    94: #eceeef,
    96: #f2f4f4,
  ),
  neutral-variant: (
    0: #000000,
    10: #141d1f,
    20: #293234,
    25: #343d3f,
    30: #3f484a,
    35: #4b5456,
    40: #576062,
    50: #6f797a,
    60: #899294,
    70: #a3adaf,
    80: #bfc8ca,
    90: #dbe4e6,
    95: #e9f2f4,
    98: #f2fbfd,
    99: #f6feff,
    100: #ffffff,
  ),
  error: (
    0: #000000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);

$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes,  neutral-variant),
  error: map.get($_palettes, error),
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);

$light-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: $_primary,
    tertiary: $_tertiary,
  ),
));
$dark-theme: mat.define-theme((
  color: (
    theme-type: dark,
    primary: $_primary,
    tertiary: $_tertiary,
  ),
));


html {
  @include mat.core-theme($dark-theme);
  @include mat.button-theme($dark-theme);
  .background-main {
    background: #06101E;
  }
  .background-sub {
    background: #212A36;
  }
  .main-text-color {
    color: #FFFFFF;
  }
  .main-subtext-color {
    color: #9FB1BB;
  }
  .logo-block-shadow {
    box-shadow: 0px -4px 16px 0px rgba(5, 13, 25, 0.32);
  }
  .drawer-info-main-text {
    color: #FFFFFF;
  }
  .drawer-info-standard-text {
    color: #FFFFFF;
  }
  .drawer-info-driver-name-text {
    color: #FFFFFF;
  }
  .drawer-info-driver-button-border-color {
    border-color: #414C57;
  }
  .drawer-info-driver-divider-color {
    border-color: #414C57;
  }
  .drawer-info-driver-previous-order-color {
    color: #9FB1BB
  }
  .expand-color {
    color: #6183B3;
  }
  .shevron-button {
    border: 1px solid #414C57;
    background: #212A36;
    box-shadow: 0 4px 12px 0 rgba(51, 86, 137, 0.16);
  }

  .finish-icon {
    color: #06101E;
  }


  // Apply the light theme only when the user prefers light themes.
  @media (prefers-color-scheme: light) {
    // Use the `-color` mixins to only apply color styles without reapplying the same
    // typography and density styles.
    .background-main {
      background: #F3F7FA;
    }
    .background-sub {
      background: #FFF;
    }
    .main-text-color {
      color: #1F3044;
    }
    .main-subtext-color {
      color: #5B5E64;
    }
    .logo-block-shadow {
      box-shadow: 0px -4px 12px 0px rgba(51, 86, 137, 0.16);
    }
    .drawer-info-main-text {
      color: #4D535F;
    }
    .drawer-info-standard-text {
      color: #335689;
    }
    .drawer-info-driver-name-text {
      color: #4D535F;
    }
    .drawer-info-driver-button-border-color {
      border-color: #EFEFF4;
    }
    .drawer-info-driver-divider-color {
      border-color: #6183B414;
    }
    .drawer-info-driver-previous-order-color {
      color: #7F848D
    }
    .expand-color {
      color: #335689;
    }
    .shevron-button {
      border: 1px solid #EFEFF4;
      background: #FFFFFF;
      box-shadow: 0 4px 12px 0 #33568929;
    }

    .finish-icon {
      color: #FFFFFF;
    }

    @include mat.core-color($light-theme);
    @include mat.button-color($light-theme);
  }
}
